
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import moment, { invalid } from "moment";
moment.locale("id");



export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      statusAllFitur : false,
      statusTransaksional : false,
      statusLayananBerbayar : false,
      statusAbsensi : false,
      statusLayananPendukung : false,

      detailFiturSekolah: {
        attendance: false,
        automaticPayment: false,
        blockCardTransaction: false,
        calendarAcademic: false,
        digitalInvoice: false,
        faceRecognition: false,
        ppdb: false,
        scheduleAcademic: false,
        usageStatus: false,
        userAdminSuspensionActiveUntil: "2023-01-31T00:55:33.345Z",
        userAdminSuspensionStatus: "ACTIVE_UNTIL",
      },
      customApps: {
        customApps: false,
        customAppsDocAgreement: "",
        customAppsDueDate: 10,
        customAppsFee: 0,
        customAppsName: "",
        customAppsNote: "",
        customAppsPaymentType: "",
        customAppsReleaseDate: "",
      },
      topUpManual: {
        topUpManual: false,
        topUpManualDocAgreement: "",
        topUpManualDueDate: 10,
        topUpManualFee: 0,
        topUpManualFeeSchool: 0,
        totalTopUpManualFee: 0,
        topUpManualNote: "",
      },
      modalFitur: {
        textTittleModal: "",
        nameFitur: "",
        valueFitur: false,
      },
      targetData:{
        uploadTopUp:false,
        fileTopUp:'',
        uploadCustomApps:false,
        fileCustomApps:''
      },

      fileInputKey:0,

      modalTopUp: false,
      modalCustomApp: false,

      isDisabled : false,

      errorMsg : {
        biayaTopUp :'',
        uploadDokTopUp :'',
        namaAplikasiCustomApp :'',
        biayaCustomApp :'',
        tipePembayaranCustomApp :'',
        uploadDokCustomApp :'',
        error:'',
        errorFile:''
      },

      rsltCekUpload : false,
      errorRsltUpload: '',
    }
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
    this.getdetail();
    // this.getCustomPrice();
  },

  methods: {
    //Consume API Function----------------

    async getdetail() {
      // loading overlay tampil
      this.isLoading = true;
      const toast = useToast();

      await ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/feature/" + this.$route.params._id
      )
      .then((res) => {
          // console.log(res)

          this.detailFiturSekolah = Object.assign({}, res.data);
          this.customApps = Object.assign({}, res.data.customApps);
          this.topUpManual = Object.assign({}, res.data.topUpManual);
          this.topUpManual.topUpManualDueDate= 10; //hardcode 03 Feb Selalu 10
          this.customApps.customAppsDueDate =10; //hardcode 03 Feb Selalu 10

          this.topUpManual.totalTopUpManualFee = res.data.topUpManual.topUpManualFeeSchool + res.data.topUpManual.topUpManualFee;
          // console.log('1',this.topUpManual.totalTopUpManualFee)
          
          // if(res.data.customApps.customAppsReleaseDate){            
          //   let text = res.data.customApps.customAppsReleaseDate;
          //   const myArray = text.split("T");
          //   this.customApps.customAppsReleaseDate = myArray[0]
          // }

          if(res.data.topUpManual.topUpManualDocAgreement == '' || res.data.topUpManual.topUpManualDocAgreement == null){
            this.topUpManual.topUpManualDocAgreement = '';
          }
          if(res.data.customApps.customAppsDocAgreement == '' || res.data.customApps.customAppsDocAgreement == null){
            this.customApps.customAppsDocAgreement = '';
          }

          this.checkAllFitur();

          this.isLoading = false;
      })
      .catch((e) => {
          this.isLoading = false;
      });
    },


    //Event Function----------------

    checkAllFitur(){
      // console.log('cek')
      if(this.detailFiturSekolah.digitalInvoice == true || this.detailFiturSekolah.automaticPayment == true || this.detailFiturSekolah.blockCardTransaction == true ){
        this.statusTransaksional = true;
      }else{
        this.statusTransaksional = false;
      }

      if(this.customApps.customApps == true || this.topUpManual.topUpManual == true || this.detailFiturSekolah.ppdb == true ){
        this.statusLayananBerbayar = true;
      }else{
        this.statusLayananBerbayar = false;
      }

      if(this.detailFiturSekolah.attendance == true || this.detailFiturSekolah.faceRecognition == true ){
        this.statusAbsensi = true;
      }else{
        this.statusAbsensi = false;
      }

      if(this.detailFiturSekolah.calendarAcademic == true || this.detailFiturSekolah.scheduleAcademic == true ){
        this.statusLayananPendukung = true;
      }else{
        this.statusLayananPendukung = false;
      }

      if(this.statusTransaksional == true || this.statusLayananBerbayar == true || this.statusAbsensi == true || this.statusLayananPendukung == true){
        this.statusAllFitur = true;
      }else{
        this.statusAllFitur = false;
      }
    },

    activeRadioButtonSelected(){
      this.errorMsg.error = '';
      this.isDisabled = false;
    },
    nonActiveRadioButtonSelected(){
      this.errorMsg.error = '';
      this.isDisabled = true;
    },

  },  

});
