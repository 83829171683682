
import { defineComponent, onMounted, ref, reactive, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import DetailSchool from "@/components/1crm/sekolah/detail-sekolah.vue";
import DetailProgressSchool from "@/components/1crm/sekolah/detail-progress-sekolah.vue";
import Detailpks from "@/components/1crm/sekolah/detail-pks.vue";
import KonfigSchool from "@/components/1crm/sekolah/konfig-detail.vue";
import penggunaanFiturPage from "@/components/1crm/sekolah/detail-penggunaanfitur.vue";
import { checkAuth } from "@/core/config/custom";

interface items {
  detailSekolah2: Boolean;
  progress2: Boolean;
  detailpks2: Boolean;
  konfig2: Boolean;
  penggunaanfitur: Boolean;
}

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    DetailSchool,
    DetailProgressSchool,
    Detailpks,
    KonfigSchool,
    penggunaanFiturPage,
  },
  data() {
    return {
      isShow: true,
      isShowPenggunaanFitur: true,
    };
  },

  beforeMount() {
    this.isShow = !checkAuth(
      JSON.parse(localStorage.getItem(`user_account`)!).role,
      [`MARKETING_LEADER`, `MARKETING_MEMBER`]
    );
    console.log("this.isShow", this.isShow);

    this.isShowPenggunaanFitur = !checkAuth(
      JSON.parse(localStorage.getItem(`user_account`)!).role,
      [`ADMIN_LEGAL`, `CS_LEADER`, `CS_MEMBER`]
    );
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let items = reactive<items>({
      detailSekolah2: true,
      progress2: false,
      detailpks2: false,
      konfig2: false,
      penggunaanfitur : false,
    });

    const detailSekolah2 = () => {
      items.detailSekolah2 = true;
      items.progress2 = false;
      items.detailpks2 = false;
      items.konfig2 = false;
      items.penggunaanfitur = false;
    };

    const progress = () => {
      items.progress2 = true;
      items.detailSekolah2 = false;
      items.detailpks2 = false;
      items.konfig2 = false;
      items.penggunaanfitur = false;
    };

    const DokumenPks2 = () => {
      items.progress2 = false;
      items.detailSekolah2 = false;
      items.detailpks2 = true;
      items.konfig2 = false;
      items.penggunaanfitur = false;
    };
    const konfig2 = () => {
      items.progress2 = false;
      items.detailSekolah2 = false;
      items.detailpks2 = false;
      items.konfig2 = true;
      items.penggunaanfitur = false;
    };
    const Penggunaanfitur = () =>{
      items.progress2 = false;
      items.detailSekolah2 = false;
      items.detailpks2 = false;
      items.konfig2 = false;
      items.penggunaanfitur = true;
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
      setCurrentPageBreadcrumbs("Detail Sekolah", ["Data"]);
    });

    return {
      submitButton,
      detailSekolah2,
      DokumenPks2,
      Penggunaanfitur,
      konfig2,
      items,
      progress,
    };
  },
});
