
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";

import Multiselect from "@vueform/multiselect";
import "vue-loading-overlay/dist/vue-loading.css";

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      url: "",
      items: {
        listBank: <any>[],
        listArea: <any>[],
        listSales: <any>[],
        listBranch: <any>[],
      },
      bankName: "",
      areaName: "",
      branchName: "",
      detail: {
        name: "",
        phone: "",
        email: "",
        initial: "",
        bankAreaId: "",
        bankBranchId: "",
        bankData: <any>[],
        picName: "",
        picPhone: "",
        picEmail: "",
        schoolType: "",
        fundingName: "",
        salesArea: "",
        picProduct: "",
        status: "",
        targetLive: "",
        customApp: "",
        progress: "",
        address: {
          province: "",
          city: "",
          regency: "",
          detail: "",
        },
        implementatorData: {},
        gps: {
          longitude: "",
          latitude: "",
        },
        npwp: {
          number: "",
        },
        referenceFrom: "",
        note: "",
        creatorName: "",
        createTime: "",
        topupFee: 0,
        automaticPayment: false,
        blockCardTransaction: false,
        faceRecognition: false,
      },
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);

    this.getListBank()
    this.getIdSekolah();

  },

  methods: {
    changeBank(e) {
      var a = ""
      e.forEach(element => {
        this.items.listBank.forEach(el => {
          if (element == el._id) {
            a = el.name + ", " + a
          }
        });
      });
      const editedText = a.slice(0, -2) //'abcde'
      console.log(editedText, "cdsfffd")
      this.bankName = editedText
    },
    getListBank() {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks").then(
        (response) => {
          this.items.listBank = response.data;
          console.log(this.items.listBank)
        }
      ).catch(e => {
        this.items.listBank = []
      });
    },
    changeListArea(e) {
      var a = ""
      e.forEach(element => {
        if (element.areaId == this.detail.bankAreaId) {
          a = element.areaName
        }
      });
      this.areaName = a
    },
    getListArea(bankId) {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/combo_areas").then(
        (response) => {
          this.items.listArea = response.data;
          console.log(response.data, "cek")
          this.changeListArea(response.data)
        }
      ).catch(e => {
        this.items.listArea = []
        this.detail.bankAreaId = ""
      });
    },
    changeListBranch(e) {
      var a = ""
      e.forEach(element => {
        if (element.branchId == this.detail.bankBranchId) {
          a = element.branchName
        }
      });
      this.branchName = a
    },
    getListBranch(bankId, areaId) {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/" + areaId + "/combo_branchs").then(
        (response) => {
          console.log(response.data, "cdf")
          this.items.listBranch = response.data;
          this.changeListBranch(response.data)
        }
      ).catch(e => {
        this.items.listBranch = []
      });
    },
    getIdSekolah() {
      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/" + this.$route.params._id
      )
        .then((res) => {
          console.log(res.data.bankData[0])
          this.changeBank(res.data.bankData)
          this.getListArea(res.data.bankData[0])
          this.getListBranch(res.data.bankData[0], res.data.bankAreaId)
          this.detail = res.data;
          this.detail.bankData = res.data.listBankName;
          this.detail.implementatorData = res.data.listImplementatorName;

          this.url =
            ApiService.vueInstance.axios.defaults.baseURL +
            "crmv2/main_school/school/school/download_npwp?schoolId=" +
            this.$route.params._id +
            "&imgFile=" +
            res.data.npwp.imageUrl;

          this.detail.note = res.data.note;
          this.detail.createTime = res.data.createTime.substring(0, 10);
          this.detail.creatorName = res.data.creatorName;
          this.detail.referenceFrom = res.data.referenceFrom;



          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },
  },
});
