
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface data {
  tableDataWebinar: any;
  tableDataPresentasi: any;
  tableDataProposal: any;
  tableDataFollowUp: any;
  tableDataCollect: any;
}

interface data2 {
  progressType: string;
  progressDate: string;
  processed: string;
  actorName: string;
  note: string;
}

interface data3 {
  progressType: string;
  progressDate: string;
  processed: string;
  actorName: string;
  contractStatus: string;
  note: string;
}

export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading, 
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    const data = reactive<data>({
      tableDataWebinar: [],
      tableDataPresentasi: [],
      tableDataProposal: [],
      tableDataFollowUp: [],
      tableDataCollect: [],
    });

    const tampilAfterSales = reactive<data2>({
      progressType: "After%20Sales",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const tampilImplementasi = reactive<data2>({
      progressType: "Implementasi",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const tampilTagihan = reactive<data2>({
      progressType: "Billing",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const tampilPreImplementasi = reactive<data2>({
      progressType: "Pre%20Implementasi",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const tampilPKS = reactive<data3>({
      progressType: "Contract",
      progressDate: "",
      processed: "",
      actorName: "",
      contractStatus: "",
      note: "",
    });

    const getProgress = () => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          data.tableDataWebinar = res.data.progress.webinar.detail;
          data.tableDataPresentasi = res.data.progress.presentation.detail;
          data.tableDataProposal = res.data.progress.proposal.detail;
          data.tableDataFollowUp = res.data.progress.followUp.detail;
          data.tableDataCollect = res.data.progress.dataCollect.detail;

          tampilPKS.progressDate = res.data.progress.contract.progressDate;
          tampilPKS.processed = res.data.progress.contract.processed;
          tampilPKS.actorName = res.data.progress.contract.actorName;
          tampilPKS.contractStatus = res.data.progress.contract.status;
          tampilPKS.note = res.data.progress.contract.note;

          tampilPreImplementasi.progressDate =
            res.data.progress.preImplementasi.progressDate;
          tampilPreImplementasi.processed =
            res.data.progress.preImplementasi.processed;
          tampilPreImplementasi.actorName =
            res.data.progress.preImplementasi.actorName;
          tampilPreImplementasi.note = res.data.progress.preImplementasi.note;

          tampilTagihan.progressDate = res.data.progress.billing.progressDate;
          tampilTagihan.processed = res.data.progress.billing.processed;
          tampilTagihan.actorName = res.data.progress.billing.actorName;
          tampilTagihan.note = res.data.progress.billing.note;

          tampilImplementasi.progressDate =
            res.data.progress.implementasi.progressDate;
          tampilImplementasi.processed =
            res.data.progress.implementasi.processed;
          tampilImplementasi.actorName =
            res.data.progress.implementasi.actorName;
          tampilImplementasi.note = res.data.progress.implementasi.note;

          tampilAfterSales.progressDate =
            res.data.progress.afterSales.progressDate;
          tampilAfterSales.processed = res.data.progress.afterSales.processed;
          tampilAfterSales.actorName = res.data.progress.afterSales.actorName;
          tampilAfterSales.note = res.data.progress.afterSales.note;

          isLoading.value = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
            isLoading.value = false;
          } else {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          }
        });
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
      getProgress();
    });

    return {
      submitButton,
      getProgress,
      data,
      tampilPKS,
      tampilPreImplementasi,
      tampilTagihan,
      tampilImplementasi,
      tampilAfterSales,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
